import React from "react";
import { getConfig } from "../config";
import styles from './Footer.module.css';

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <div className="logo" />
    <p>
      Sample project provided by <a href="https://auth0.com">Auth0</a>
    </p>
  <div className={'offset-md-3 w-50 ' + styles.midfoot}>
  <pre><code>config:{JSON.stringify(getConfig(), null, 2)}</code></pre>
  </div>
  </footer>
);

export default Footer;
