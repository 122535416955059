import React, { useState } from "react";
// import {  Alert } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Input, InputGroup, InputGroupText, Button} from "reactstrap";
import Highlight from "../components/Highlight";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import { useRocksAPI } from '../services/api';
import Loading from "../components/Loading";

export const ExternalApiComponent = () => {
  const { apiPort, apiOrigin = `http://localhost:${apiPort}`, audience } = getConfig();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    api_path: "external",
    error: null,
  });


  const onFormInputChange = ({ target }) => {

    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const [rocksapi] = useRocksAPI();

  const {
    user,
  } = useAuth0();

  const callApi = async () => {

    setState({
      ...state,
      showResult: false,
      apiMessage: '',
    });
    const response = await rocksapi.get(state.api_path);

    setState({
      ...state,
      showResult: true,
      apiMessage: response.data,
    });

  };

  return (
    <>
      <div className="mb-5">
        <h1>External API</h1>
        <p className="lead">
          Hello, {user.name}! <br/>Ping the external API by clicking the button below. There are several paths you can try.
        </p>
        <ul>
          <li><code>external</code> is a simple test of the api authentication</li>
          <li><code>home/content</code> is the unauthenticated source for the homepage of this application</li>
          <li><code>puppy/random</code> will return a random puppy image</li>
          <li><code>puppy/random/&lt;n&gt;</code> will return <code>n</code> number of random puppy images</li>

        </ul>

        <p>
          This will call a local API at <code>{apiOrigin}</code>. An access
    token is sent as part of the request's <code>Authorization</code> header. The API
    will validate it using the API's audience value.
        </p>

        <InputGroup>
          <InputGroupText>
           /api/v1/
          </InputGroupText>
          <Input placeholder="external" name='api_path' onChange={onFormInputChange}/>
        </InputGroup>

        <Button
          color="primary"
          className="mt-5"
          onClick={callApi}
          disabled={!audience}
        >
          Ping the API {audience}
        </Button>
      </div>

      <div className="result-block-container">
        {state.showResult && (
          <div className="result-block" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <Highlight>
              <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
            </Highlight>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(ExternalApiComponent, {
  onRedirecting: () => <Loading />,
});
