import React from "react";

import logo from "../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src='https://webobjects2.cdw.com/is/image/CDW/cdw-logo-220x130-2023-v2' alt="React logo" width="120" />
    <h1 className="mb-4">Rocks Portal</h1>

    <p className="lead">
      This is a sample application that demonstrates an authentication flow for
      an Single Page Application (SPA).
    </p>
  </div>
);

export default Hero;
