import React from "react";
import { useState, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { getContent, contentData } from "../utils/contentData";
import { HomeContentAPI, RocksAPI, useRocksAPI } from '../services/api';
import { getConfig } from "../config";

/*
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {content: []};
  }

  componentDidMount() {
    console.log('in componentDidMount');

    doSomeSetup();
  }

  doSomeSetup(){
    const {
      getAccessTokenSilently,
    } = useAuth0();
    console.log('in here');

    const token = getAccessTokenSilently().then((token)=>{
      FetchHomeContent(token).then((r, d)=>{
        console.log('response', r, d);
        this.setState({
            content: r
        });
      });

    });

  }

  render() {

    console.log(this.state.content)
    return (
      <div className="next-steps my-5">
        <h2 className="my-5 text-center">What can I do next?</h2>
        <Row className="d-flex justify-content-between">
          {this.state.content.map((col, i) => (
            <Col key={i} md={5} className="mb-4">
              <h6 className="mb-3">
                <a href={col.link}>
                  <FontAwesomeIcon icon="link" className="mr-2" />
                  {col.title}
                </a>
              </h6>
              <p>{col.description}</p>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}
*/

const Content = () => {
  // const [content, setContent] = useState(0);

  const [state, setState] = useState({
    contents: [],
  });
  const { apiPort, apiOrigin = `http://localhost:${apiPort}` } = getConfig();

  // const [data, loading, error] = usePuppyAPI('random');

  // console.log('data', data, loading, error);
  // const rocksapi = new RocksAPI(null, apiOrigin);
  const {
    getAccessTokenSilently,
    //loginWithPopup,
    //getAccessTokenWithPopup,
  } = useAuth0();

  const {rocksapi} = useRocksAPI();  // new RocksAPI(null, apiOrigin);

  useEffect(() => {
    HomeContentAPI().then((r)=>{
      setState({'contents': r.data});
    });
    /*
    */

    // const puppy_api = new RocksAPI(token, apiOrigin);
    /*
    console.log('rocksapi', rocksapi);
    rocksapi.get('/home/content').then((r)=>{
      console.log('r', r);
      setState({'contents': r.data});
    });
    */

  }, []);

  //console.log(content);
  /*
  */
  /*
  return (
    <div>Hello</div>
  )
  */
  return (
    <div className="next-steps my-5">
      <h2 className="my-5 text-center">What can I do next?</h2>
      <Row className="d-flex justify-content-between">
        {state.contents.map((col, i) => (
          <Col key={i} md={5} className="mb-4">
            <h6 className="mb-3">
              <a href={col.link}>
                <FontAwesomeIcon icon="link" className="mr-2" />
                {col.title}
              </a>
            </h6>
            <p>{col.description}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
  /*
  */
};


export default Content;
